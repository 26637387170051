import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import logo from './logo.png'; // Add your friendship bracelet logo image here

function Navbar() {
  return (
    <AppBar position="static" style={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
      <Toolbar style={{ justifyContent: 'center' }}>
        <img
          src={logo}
          alt="Friendship Bracelet Logo"
          style={{
            height: '50px',
          }}
        />
        {/* Optionally, add a title or tagline under the logo */}
        {/* <Typography variant="h6" style={{ color: '#BF4E83', marginLeft: '10px' }}>
          Invisible Strings
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
