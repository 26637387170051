import React, { useState } from 'react';
import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import './Hello.css'; // Use the same CSS for consistency with Quicksand font and other styles
import logo from './logo.png'; // Import the logo image

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); 

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      // Call the backend API to reset the password
      const response = await fetch(apiUrl+'/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword, token }),
      });

      if (response.ok) {
        // Set a success message
        setMessage('Your password has been reset successfully.');
      } else {
        // Handle error if the response is not ok
        const errorData = await response.json();
        setMessage(errorData.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      setMessage('An error occurred. Please try again later.');
      console.error('Error resetting password:', error);
    }
  };

  return (
    <div className="hello-container">
      <Card className="hello-inner-container" style={{ textAlign: 'center', padding: '20px', width: '100%', maxWidth: '400px' }}>
        <CardContent>
          <img alt="Invisible Strings" src={logo} className="hello-logo" />
          <Typography variant="h4" component="h1" gutterBottom style={{ color: '#BF4E83', fontFamily: 'Quicksand, sans-serif' }}>
            Reset Your Password
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <TextField
              type="password"
              label="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{ style: { color: '#BF4E83' } }}
              InputProps={{ style: { color: '#4A4A4A', borderColor: '#BF4E83', fontFamily: 'Quicksand, sans-serif' } }}
            />
            <TextField
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{ style: { color: '#BF4E83' } }}
              InputProps={{ style: { color: '#4A4A4A', borderColor: '#BF4E83', fontFamily: 'Quicksand, sans-serif' } }}
            />
            {message && (
              <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '10px', fontFamily: 'Quicksand, sans-serif' }}>
                {message}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: '#71BBD9',
                color: '#ffffff',
                marginTop: '1rem',
                fontFamily: 'Quicksand, sans-serif'
              }}
              className="login-button"
            >
              Reset Password
            </Button>
          </form>
          <Typography variant="body2" style={{ color: '#BF4E83', marginTop: '20px', fontFamily: 'Quicksand, sans-serif' }}>
            Remembered your password?{' '}
            <a href="/login" style={{ color: '#71BBD9', textDecoration: 'none' }}>
              Log In
            </a>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetPassword;
