import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        // Save the current location to redirect back to after login
        navigate('/hello', { state: { from: location } });
        return;
      }

      try {
        const response = await axios.get(apiUrl+'/check-token', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          setAuthorized(true);
        } else {
          navigate('/hello', { state: { from: location } });
        }
      } catch (error) {
        navigate('/hello', { state: { from: location } });
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate, location]);

  if (loading) return <div>Loading...</div>;
  if (!authorized) return null;

  return children;
}

export default ProtectedRoute;
