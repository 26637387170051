import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import Autocomplete from 'react-google-autocomplete'; // Import the autocomplete component
import './Bracelet.css'; // Import the CSS file for custom styles
import logo from './logo.png'; // Import the logo image

function Bracelet() {
  const [location, setLocation] = useState('');
  const [message, setMessage] = useState('');
  const [trades, setTrades] = useState([]);
  const [tradeLocation, setTradeLocation] = useState('');
  const [tradeMessage, setTradeMessage] = useState('');
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [braceletFound, setBraceletFound] = useState(false); // Track if a bracelet is found
  const [statusMessage, setStatusMessage] = useState(''); // For showing status messages
  const navigate = useNavigate();
  const email = localStorage.getItem('userEmail');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBraceletDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const urlParams = new URLSearchParams(window.location.search);
        const qrCode = urlParams.get('qrCode');

        if (!qrCode) {
          setStatusMessage('No QR code found in the URL');
          return;
        }

        const response = await fetch(apiUrl + `/get-bracelet?token=${encodeURIComponent(qrCode)}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const braceletData = await response.json();
          if (braceletData) {
            // Bracelet found, set the form to show register form
            setBraceletFound(true);
            setUserEmail(braceletData.user_email);
            setLocation(braceletData.location);
            setMessage(braceletData.message);
            setTrades(braceletData.trades || []);
          } else {
            // No bracelet found, show the trade form
            setBraceletFound(false);
          }
        } else {
          setStatusMessage('Failed to fetch bracelet details.');
        }
      } catch (error) {
        console.error('Error fetching bracelet details:', error.message);
        setStatusMessage('Error fetching bracelet details.');
      } finally {
        setLoading(false);
      }
    };

    fetchBraceletDetails();
  }, [apiUrl]);

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage(''); // Clear any previous status message

    try {
      const token = localStorage.getItem('token');
      const urlParams = new URLSearchParams(window.location.search);
      const qrCode = urlParams.get('qrCode');
      const response = await fetch(apiUrl + '/update-bracelet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          message: message,
          location: location,
          token: qrCode,
        }),
      });

      if (response.ok) {
        setStatusMessage('Bracelet updated successfully!');
        navigate(`/feed`);
      } else {
        setStatusMessage('Failed to update bracelet.');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setStatusMessage('Error updating bracelet.');
    }
  };

  const handleMakeTradeSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage(''); // Clear any previous status message

    try {
      const token = localStorage.getItem('token');
      const urlParams = new URLSearchParams(window.location.search);
      const qrCode = urlParams.get('qrCode');
      const response = await fetch(apiUrl + '/trades', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          message: tradeMessage,
          location: tradeLocation,
          token: qrCode,
        }),
      });

      if (response.ok) {
        setStatusMessage('Trade created successfully!');
        navigate(`/feed`);
      } else {
        setStatusMessage('Failed to create trade.');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setStatusMessage('Error creating trade.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  return (
    <div className="bracelet-container" style={{ background: 'linear-gradient(135deg, #F2C4D0, #71BBD9)', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card className="bracelet-box" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          {statusMessage && (
            <Typography variant="body2" style={{ color: '#BF4E83', textAlign: 'center', marginBottom: '10px' }}>
              {statusMessage}
            </Typography>
          )}
          {braceletFound ? (
            // Show the register form if bracelet is found
            <div>
              <Typography variant="h5" className="trade-title" gutterBottom style={{ color: '#BF4E83', textAlign: 'center' }}>
                Register
              </Typography>
              <form onSubmit={handleRegisterSubmit}>
                <TextField
                  id="message"
                  label="Lyric or Message"
                  placeholder="Add a lyric or message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
                <Autocomplete
                  id="location"
                  placeholder="Add location"
                  onPlaceSelected={(place) => setLocation(place.formatted_address)}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} // Set your Google Maps API key
                  options={{
                    types: ['geocode'], // You can restrict the types of places to 'geocode'
                  }}
                  fullWidth
                  margin="normal"
                  value={location}
                />
                <Button
                  type="submit"
                  className="bracelet-button"
                  style={{ backgroundColor: '#BF4E83', color: '#FFFFFF', marginTop: '20px', padding: '10px 20px', borderRadius: '8px' }}
                >
                  Register
                </Button>
              </form>
            </div>
          ) : (
            // Show the trade form if no bracelet is found
            <div>
              <Typography variant="h5" className="trade-title" gutterBottom style={{ color: '#BF4E83', textAlign: 'center' }}>
                Trade
              </Typography>
              <form onSubmit={handleMakeTradeSubmit}>
                <TextField
                  id="tradeMessage"
                  label="Love Note"
                  placeholder="Add a love note for the creator"
                  value={tradeMessage}
                  onChange={(e) => setTradeMessage(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
                <Autocomplete
                  id="tradeLocation"
                  placeholder="Add location"
                  onPlaceSelected={(place) => setTradeLocation(place.formatted_address)}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} // Set your Google Maps API key
                  options={{
                    types: ['geocode'], // You can restrict the types of places to 'geocode'
                  }}
                  fullWidth
                  margin="normal"
                  value={tradeLocation}
                />
                <Button
                  type="submit"
                  className="bracelet-button"
                  style={{ backgroundColor: '#BF4E83', color: '#FFFFFF', marginTop: '20px', padding: '10px 20px', borderRadius: '8px' }}
                >
                  Trade
                </Button>
              </form>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default Bracelet;
