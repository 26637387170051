import React, { useState } from 'react';
import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import './Hello.css'; // Use the same CSS for consistency with Quicksand font and other styles
import logo from './logo.png'; // Import the logo image

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Call the backend API to send the reset email
      const response = await fetch(apiUrl+'/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        // Set a success message
        setMessage('If this email is registered, a reset link will be sent to it.');
      } else {
        // Handle error if the response is not ok
        const errorData = await response.json();
        setMessage(errorData.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network errors or other unexpected errors
      setMessage('An error occurred. Please try again later.');
      console.error('Error sending reset email:', error);
    }
  };

  return (
    <div className="hello-container">
      <Card className="hello-inner-container" style={{ textAlign: 'center', padding: '20px', width: '100%', maxWidth: '400px' }}>
        <CardContent>
          <img alt="Invisible Strings" src={logo} className="hello-logo" />
          <Typography variant="h4" component="h1" gutterBottom style={{ color: '#BF4E83', fontFamily: 'Quicksand, sans-serif' }}>
            Forgot Your Password?
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{ style: { color: '#BF4E83' } }}
              InputProps={{ style: { color: '#4A4A4A', borderColor: '#BF4E83', fontFamily: 'Quicksand, sans-serif' } }}
            />
            {message && (
              <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '10px', fontFamily: 'Quicksand, sans-serif' }}>
                {message}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: '#71BBD9',
                color: '#ffffff',
                marginTop: '1rem',
                fontFamily: 'Quicksand, sans-serif'
              }}
              className="login-button"
            >
              Send Reset Link
            </Button>
          </form>
          <Typography variant="body2" style={{ color: '#BF4E83', marginTop: '20px', fontFamily: 'Quicksand, sans-serif' }}>
            Remember your password?{' '}
            <a href="/login" style={{ color: '#71BBD9', textDecoration: 'none' }}>
              Log In
            </a>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default ForgotPassword;
