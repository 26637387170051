import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, CardContent, TextField, Typography, Box } from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
//import { FacebookIcon } from './CustomIcons';
import './Hello.css'; // Use the same CSS as the Hello component for consistency
import logo from './logo.png'; // Import the logo image

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State to handle error messages
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message on new submission

    try {
      const response = await fetch(apiUrl + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        localStorage.setItem('token', token);
        localStorage.setItem('userEmail', email);
        const redirectTo = location.state?.from?.pathname + location.state?.from?.search || '/feed';
        navigate(redirectTo, { replace: true });
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to log in. Please try again.'); // Set the error message
      }
    } catch (error) {
      console.log(error);
      setError('An error occurred. Please try again later.');
    }
  };

  const handleForgotPasswordRedirect = () => {
    navigate('/forgot-password');
  };

  const handleGoogleSuccess = (response) => {
    const idToken = response.credential;

    fetch(apiUrl + '/google-signin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idToken }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem('token', data.token); // Save the JWT token
          const redirectTo = location.state?.from?.pathname + location.state?.from?.search || '/feed';
          navigate(redirectTo, { replace: true });
        } else {
          setError('Google Sign-In failed');
        }
      })
      .catch((err) => setError('An error occurred during Google Sign-In.'));
  };

  const handleGoogleFailure = () => {
    setError('Google Sign-In failed. Please try again.');
  };

  const handleFacebookSuccess = (response) => {
    const { accessToken, userID } = response;

    fetch(apiUrl + '/facebook-signin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken, userID }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem('token', data.token); // Save the JWT token
          navigate('/feed', { replace: true });
        } else {
          setError('Facebook Sign-In failed');
        }
      })
      .catch((err) => setError('An error occurred during Facebook Sign-In.'));
  };

  const handleFacebookFailure = () => {
    setError('Facebook Sign-In failed. Please try again.');
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="hello-container">
        <Card className="hello-inner-container" style={{ textAlign: 'center', padding: '20px', width: '100%', maxWidth: '400px' }}>
          <CardContent>
            <img alt="Invisible Strings" src={logo} className="hello-logo" />
            <Typography variant="h4" component="h1" gutterBottom style={{ color: '#BF4E83', fontFamily: 'Quicksand, sans-serif' }}>
              Welcome Back!
            </Typography>
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              <TextField
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{ style: { color: '#BF4E83' } }}
                InputProps={{ style: { color: '#4A4A4A', borderColor: '#BF4E83', fontFamily: 'Quicksand, sans-serif' } }}
              />
              <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{ style: { color: '#BF4E83' } }}
                InputProps={{ style: { color: '#4A4A4A', borderColor: '#BF4E83', fontFamily: 'Quicksand, sans-serif' } }}
              />
              {error && (
                <Typography variant="body2" style={{ color: 'red', marginTop: '10px', fontFamily: 'Quicksand, sans-serif' }}>
                  {error}
                </Typography>
              )}
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '0' }}>
              <Typography
                variant="body2"
                onClick={handleForgotPasswordRedirect}
                className="forgot-password"
                style={{ 
                  cursor: 'pointer', 
                  color: '#BF4E83', 
                  fontFamily: 'Quicksand, sans-serif',
                  textAlign: 'right',
                  display: 'block',
                  padding: '0' // Remove padding
                }}
              >
                Forgot Password?
              </Typography>
            </CardContent>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: '#71BBD9',
                  color: '#ffffff',
                  marginTop: '1rem',
                  fontFamily: 'Quicksand, sans-serif',
                }}
                className="login-button"
              >
                Log In
              </Button>
            </form>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '20px' }}>
              <div style={{ width: '100%' }}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  useOneTap
                  style={{ width: '100%' }} // Ensure button maintains full width
                />
              </div>
              {/* <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                callback={handleFacebookSuccess}
                onFailure={handleFacebookFailure}
                render={(renderProps) => (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={renderProps.onClick}
                    startIcon={<FacebookIcon />}
                    style={{ borderColor: '#F2C4D0', color: '#4A4A4A', fontFamily: 'Quicksand, sans-serif' }}
                  >
                    Log in with Facebook
                  </Button>
                )}
              /> */}
            </Box>
            <Typography variant="body2" className="sign-up-link" style={{ color: '#BF4E83', marginTop: '20px', fontFamily: 'Quicksand, sans-serif' }}>
              Don't have an account?{' '}
              <a href="/signup" style={{ color: '#71BBD9', textDecoration: 'none' }}>
                Sign Up
              </a>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Login;

