import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Grid, Box, Card, CardContent } from '@mui/material';
import './Feed.css'; // Custom CSS for additional styling
import './Hello.css'; // Reuse CSS for consistent styles
import Navbar from './Navbar'; // Adjust the path as necessary

function Dashboard() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBracelets = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('You are not authorized. Please log in.');
        navigate('/login');
        return [];
      }

      try {
        const response = await fetch(apiUrl + '/bracelets/list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch bracelets.');

        const data = await response.json();

        // Check if data is null or undefined, return empty array if so
        if (!data || !Array.isArray(data)) {
          return [];
        }

        const eventItems = data.flatMap((bracelet) => {
          const createdEvent = {
            message: bracelet.message,
            eventType: 'CREATED',
            created_at: bracelet.created_at,
          };

          const tradeEvents = (bracelet.trades || []).map((trade) => ({
            location: trade.location,
            message: bracelet.message,
            tradeMessage: trade.message,
            eventType: 'TRADED',
            created_at: trade.created_at,
          }));

          return [createdEvent, ...tradeEvents];
        });

        return eventItems;
      } catch (error) {
        console.error('Error fetching bracelets:', error);
        setError('Error fetching bracelets.');
        return [];
      }
    };

    const fetchTrades = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('You are not authorized. Please log in.');
        navigate('/login');
        return [];
      }

      try {
        const response = await fetch(apiUrl + '/trades/list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch trades.');

        const data = await response.json();

        // Check if data is null or undefined, return empty array if so
        if (!data || !Array.isArray(data)) {
          return [];
        }

        const eventItems = data
          .filter((trade) => trade.location && trade.bracelet_message && trade.message && trade.created_at)
          .map((trade) => ({
            location: trade.location,
            message: trade.bracelet_message.String,
            eventType: 'RECEIVED',
            created_at: trade.created_at,
          }));

        return eventItems;
      } catch (error) {
        console.error('Error fetching trades:', error);
        setError('Error fetching trades.');
        return [];
      }
    };

    const fetchData = async () => {
      const braceletEvents = await fetchBracelets();
      const tradeEvents = await fetchTrades();

      const allEvents = [...braceletEvents, ...tradeEvents].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setEvents(allEvents);
    };

    fetchData();
  }, [navigate]);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(135deg, #F2C4D0, #71BBD9)', // Background covers the whole page
        fontFamily: 'Quicksand, sans-serif',
      }}
    >
      {/* Header at the top */}
      <Navbar />

      {/* Main Content */}
      <Container
        maxWidth="md"
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
        }}
      >
        <Card style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }}>
          <CardContent>
            {error && (
              <Typography variant="body2" style={{ color: 'red', textAlign: 'center', marginBottom: '20px' }}>
                {error}
              </Typography>
            )}
            <section>
              {events.length === 0 ? (
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <Box
                      style={{
                        textAlign: 'center',
                        padding: '30px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '12px',
                      }}
                    >
                      <Typography variant="h6" style={{ color: '#BF4E83' }}>
                        No events yet.{' '}
                        <a
                          href="https://4c3a9f-be.myshopify.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: '#BF4E83' }}
                        >
                          Get your bracelet QR code now!
                        </a>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  {events.map((event, index) => (
                    <Grid item xs={12} key={index}>
                      <Box
                        style={{
                          padding: '20px',
                          backgroundColor: '#FFFFFF',
                          borderRadius: '12px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <Typography variant="body2" style={{ color: '#7A7A7A' }}>
                          {formatTimestamp(event.created_at)}
                        </Typography>
                        <Typography variant="h6" style={{ color: '#BF4E83', marginTop: '10px' }}>
                          {event.eventType} {typeof event.location === 'string' && `· ${event.location}`}
                        </Typography>
                        <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '5px' }}>
                          {typeof event.message === 'string' ? event.message : 'Invalid message'}
                        </Typography>
                        {event.tradeMessage && typeof event.tradeMessage === 'string' && (
                          <Typography variant="body2" style={{ color: '#4A4A4A', marginTop: '10px' }}>
                            <strong>Love Note:</strong> {event.tradeMessage}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </section>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default Dashboard;
