import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link from react-router-dom
import './Hello.css'; // Import custom CSS for styling
import logo from './logo.png'; // Import the local logo image


function Hello() {
  const location = useLocation();
  const state = location.state || {}; 
  console.log(state)
  return (
    <div className="hello-container">
      <div className="hello-inner-container">
        <img
          alt="Invisible Strings"
          src={logo} // Use the local logo image
          className="hello-logo"
        />
        <h2 className="hello-heading">
          Nice to meet you!
        </h2>

        <div className="hello-button-container">
          {/* Use Link components to navigate to signup and login */}
          <Link to="/signup"  state={state}>
            <button className="hello-button hello-signup-button">First Trade?</button>
          </Link>
          <Link to="/login"  state={state}>
            <button className="hello-button hello-login-button">Log In</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hello;

